// Import necessary dependencies
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import colorSharp from "../assets/img/color-sharp.png";

// Define the Skills component
export const Skills = () => {
    // Responsive settings for the carousel
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <section className="skills" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx">
                            <h2>Skills</h2>
                            <p>
                                C#, .NET, Java, JavaScript, TypeScript, C++, C, SQL, Git, GitHub, Linux Environment, Object-Oriented Programming (OOP), ASP MVC .NET
                            </p>
                            <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                {/* Skill items */}
                                <div className="item">
                                    <img src={meter1} alt="Image" />
                                    <h5>Software Development</h5>
                                </div>

                                <div className="item">
                                    <img src={meter2} alt="Image" />
                                    <h5>Java Development</h5>
                                </div>

                                <div className="item">
                                    <img src={meter3} alt="Image" />
                                    <h5>C / C++ Development</h5>
                                </div>

                                <div className="item">
                                    <img src={meter1} alt="Image" />
                                    <h5>Git / GitHub</h5>
                                </div>

                                <div className="item">
                                    <img src={meter2} alt="Image" />
                                    <h5>Apex Legends Predator</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Background image */}
            <img className="background-image-left" src={colorSharp} alt="Background" />
        </section>
    );
};
